<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Agencias Multilaterales | Administración
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color=""
              dark
              class="mb-2 float-right light-blue-502"
              @click="modalNuevo"
            >
              Registrar agencia multilateral
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="items"
          :search="filtro"
          :loading="tableLoading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class=""
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Nombre de la agencia, fuente multilateral"
                    maxlength="200"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <!-- <td>{{ item.id }}</td> -->
              <td>{{ item.agencia }}</td>
              <td>{{ item.nombreFuenteMultilateral }}</td>
             <!-- <td>
                {{
                  item.nombreMaximaAutoridad != null
                    ? item.nombreMaximaAutoridad
                    : ""
                }}
              </td>-->
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id)"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>                
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: dialog suspension -->
    <v-dialog v-model="dialogSuspension" width="500" transition="scroll-y-transition">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Suspensión del proyecto
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col sm="12">
              Está seguro de suspender el proyecto {{ proyectoSeleccionado }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="900"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ accion === 1 ? `Registrar` : `Actualizar` }}  Agencia Multilateral

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false || resetForm()"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-row>
              <v-col cols="12" md="12" class="pt-4 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.agencia"
                  label="Nombre de la agencia"
                  :rules="[
                    required('Nombre de la agencia'),
                    minLength('Nombre de la agencia', 3),
                    maxLength('Nombre de la agencia', 100)
                  ]"
                  maxlength="100"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-autocomplete
                  dense
                  filled
                  v-model="datosItem.fuentesMultilateralesId"
                  :items="fuentesMultilaterales"
                  class="required"
                  color="blue-grey lighten-2"
                  :loading="false"
                  label="Fuente Multilateral"
                  item-text="nombreFuenteMultilateral"
                  item-value="id"
                  :rules="[selectRequired('Fuente multilateral')]"
                  menu-props="auto"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" sm="12" class="pt-0 pb-0">
                <label class="grey--text body-2 mb-0 font-weight-medium"
                  >Estado</label
                >
                <template>
                  <v-switch
                    class="pt-0 mt-1"
                    v-model="switchItemEstado"
                    inset
                    :label="switchItemEstado ? 'Activo' : 'Inactivo'"
                  >
                  </v-switch>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            :disabled="btnRegistroLoading"
          >
            Cancelar
          </v-btn>
          <!--:disabled="!validDocForm" type="submit"-->
          <v-btn
            color="light-blue-502"
            class="white--text mb-2 float-right"
            type="submit"
            :elevation="0"
            :disabled="!validForm"
            :loading="btnRegistroLoading"
            @click="registrarItem"
          >
            {{btnRegistroText}}
          </v-btn>
        </v-card-actions>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>

    <!--end:: modal registro-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

import validations from "@/core/untils/validations.js";

import { OBTENER_FUENTES_MULTILATERALES } from "@/core/services/store/catalogos/fuentesmultilaterales/fuentemultilateral.module";
import { OBTENER_AGENCIAS_MULTILATERALES, OBTENER_AGENCIA_MULTILATERAL, REGISTRAR_AGENCIA_MULTILATERAL, ACTUALIZAR_AGENCIA_MULTILATERAL } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";




export default {
  name: "AdminAgenciasMultilaterales",
  data() {
    return {
      validForm: false,
      ddRolesLoading: false,
      dialogRegistro: false,
      dialogLoaderVisible:false,
      dialogLoaderText: '',
      tableLoading: false,
      dialogSuspension: false,
      switchItemEstado: true,
      proyectoSeleccionado: "",
      filtro: "",
      dialog: false,
      modalTitle: "Registrar agencia multilateral",
      accion: 1,
      items: [],
      fuentesMultilaterales: [],
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      estadoProyecto: "",
      datosItem: {
        id: 0,
        agencia: "",
        fuentesMultilateralesId: 0,
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      estado: "",
      estados: [
        { text: "Todos", value: null },
        { value: 1, text: "Activo" },
        { value: 2, text: "Inactivo" }
      ],
      ...validations,
     

      
    };
  },
  components: {
    DialogLoader,
    SnackAlert
  },
  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estado.text;
    },

    //Obtener las fuentesMultilaterales
    async obtenerFuentesMultilaterales() {
      this.fuentesMultilaterales = [];
      

      await this.$store
        .dispatch(OBTENER_FUENTES_MULTILATERALES, { estadoId: 1, paisId: 0})
        .then(res => {
            if(res.status===200){
                this.fuentesMultilaterales = res.data;
            }
        })
        .catch(() => {
          this.fuentesMultilaterales = [];
        });
    },

    // Obtener agencias bilaterales
    async obtenerItems() {
      this.items = [];
      this.tableLoading = true;

      await this.$store
        .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {estadoId:0, fuentesMultilateralesId: 0})
        .then(res => {
            if(res.status===200){

                this.items = res.data;
            }
          this.tableLoading = false;
        })
        .catch(error => {
            console.log(error)
          this.tableLoading = false;
        });
    },

    

    modalNuevo() {
      this.accion = 1;
      this.modalTitle = "Registrar unidad ejecutora";
      this.btnRegistroText = "Guardar";
      this.switchItemEstado = true;
      this.dialogRegistro = true;
    },



    ///Registror unidad ejecutora
    async registrarItem() {
      this.btnRegistroLoading = true;

      //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
      this.switchItemEstado
        ? (this.datosItem.estadosId = 1)
        : (this.datosItem.estadosId = 2);

      this.datosItem.usuarioCreacion= "admin";

      let dispatch = REGISTRAR_AGENCIA_MULTILATERAL;

      if(this.accion===2){
        dispatch = ACTUALIZAR_AGENCIA_MULTILATERAL;
      }


      //?Si accion es 1 se realiza el registro
      
        await this.$store
          .dispatch(dispatch, this.datosItem)
          .then(res => {
            this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
              this.obtenerItems();
              this.dialogRegistro = false;
              this.resetForm();
            } else {
              this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
            }
            this.btnRegistroLoading = false;
          })
          .catch(error => {
            this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro. ${error}`);
          });
    },



    //!Obtener datos de un item especifico
    async obtenerDatosItem(Id) {
     
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";
      this.dialogTitulo = `Actualizar ${this.itemName}`;
     
      this.btnRegistroText = `Actualizar`;
      this.datosItem={};
      await this.$store
        .dispatch(OBTENER_AGENCIA_MULTILATERAL, Id)
        .then(res => {
          //console.log(res);
          if (res.status === 200) {
            this.datosItem = res.data;
            this.datosItem.estadosId === 1
              ? (this.switchItemEstado = true)
              : (this.switchItemEstado = false);
            this.accion = 2;
            //this.modalTitle = `Actualizar unidad ejecutora ${this.datosItem.nombreUnidadEjecutora}`
            this.dialogRegistro = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          console.log(error)
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
          );
          
        });
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
    },

    //Mostrar el dialogo para la suspension
    mostrarDialogSuspension(nombreProyecto) {
      this.proyectoSeleccionado = nombreProyecto;
      this.dialogSuspension = true;
    },

    resetForm() {

      this.datosItem = {
        id: 0,
        agencia: "",
        fuentesMultilateralesId: 0,
        estadosId: 1,
        usuarioCreacion: "admin",
      }

    },
  
  },
  created(){
    this.obtenerItems();
    this.obtenerFuentesMultilaterales();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Administracion" },
      { title: "Agencias Multilaterales" }
    ]);
  }, 

  computed: {
    headers(){
     return [
       /*  {
          text: "Id",
          align: "start",
          sortable: false,
          value: "codigo"
        }, */
        {
          text: "Nombre de la Agencia Multilateral",
          align: "start",
          sortable: true,
          value: "nombreAgencia"
        },
        {
          text: "Fuente Multilateral",
          align: "start",
          sortable: true,
          value: "nombre"
        },
       /* {
          text: "Máxima autoridad",
          align: "start",
          sortable: true,
          value: "nombreMaximaAutoridad"
        },*/
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ]
    }
  }
};
</script>
